<!-- Error page-->
<div class="misc-wrapper">
  <a (click)="backToHome()" class="brand-logo">
    <img alt="brand-logo" height="28" src="{{ coreConfig.app.appLogoImage }}"/>
    <h2 class="brand-text text-primary ml-1">Caleidoscoop</h2></a
  >
  <div class="misc-inner p-2 p-sm-3">
    <div class="w-100 text-center">
      <h2 class="mb-1">{{'PAGE_NOT_FOUND' | translate}}️</h2>
      <p class="mb-2">{{'REQUESTED_URL_NOT_FOUND' | translate}}</p>
      <button (click)="backToHome()" class="btn btn-primary mb-1 btn-sm-block" rippleEffect
      >{{'BACK_TO_HOME' | translate }}</button>
      <img
      [src]="
          coreConfig.layout.skin === 'dark' ? 'assets/images/pages/error-dark.svg' : 'assets/images/pages/error.svg'
        "
      alt="Error page"
      class="img-fluid"
    />
    </div>
  </div>
</div>
<!-- / Error page-->
