import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';

@Component({
  selector: '[core-menu-vertical-icon]',
  templateUrl: './icon.component.html',
  styleUrls: ['icon.component.scss']
})
export class CoreMenuVerticalIconComponent {
  @Input()
  item: CoreMenuItem;
}
