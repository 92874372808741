<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36"/>
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appTitle }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content flex-wrap align-items-center">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul *ngIf="!coreConfig.layout.menu.hidden" class="nav navbar-nav d-xl-none margin-menu-mobile">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block mr-2">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()"
         ngbTooltip="{{currentSkin === 'dark' ? ('LIGHT_MODE'|translate) : ('DARK_MODE'|translate)}}"
         placement="right" [openDelay]="300">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>
    <!--/ Toggle skin -->
  </div>

  <div class="col-7 col-md-5 p-0 row ml-0">
    <div class="nav-item dropdown-use" ngbDropdown>
      <a [href]="platformConfigurationService.getMagazineUrl()"
         class="btn btn-outline-tertiary scale-mobile-btn">{{'MAGAZINE'|translate}}</a>
    </div>
  </div>


  <ul class="nav navbar-nav align-items-center justify-content-end ml-auto">

    <li *ngIf="!isLoggedIn" class="nav-item dropdown-user" ngbDropdown>
      <a [href]="platformConfigurationService.getPlatformUrl()+'/login'"
         class="btn btn-primary scale-mobile-btn">{{'SIGN_IN'|translate}}</a>
    </li>

    <!-- User Dropdown -->
    <li *ngIf="isLoggedIn" class="nav-item dropdown-user" ngbDropdown>
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-md-flex d-none">
          <span class="user-name font-weight-bolder">{{this.authService.getUsername()}}</span>
        </div>
        <span class="avatar"
        ><img
          class="round cursor-pointer"
          [src]="(profileService.profilePicture | async) || stockImage"
          alt="avatar"
          height="40"
          width="40"/><span class="avatar-status-online"></span
        ></span>
      </a>
      <div aria-labelledby="navbarUserDropdown"
           class="dropdown-menu dropdown-menu-right {{isAcademieUser()?'mt-default-nav-dropdown': 'mt-mobile-nav-dropdown'}}"
           ngbDropdownMenu>
        <a [routerLink]="['/account/profile']" ngbDropdownItem><span [class]="'mr-50'"
                                                             [data-feather]="'user'"></span>{{'MY_ACCOUNT'|translate}}
        </a>
        <div class="dropdown-divider"></div>
        <a href="/loader/logout/sso" ngbDropdownItem><span [class]="'mr-50'" [data-feather]="'power'"></span>{{'LOGOUT'|translate}}</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
