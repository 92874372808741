<ng-container *ngIf="!item.hidden">
  <!-- item with router url -->
  <a class="ml-1"
    [ngClass]="item.classes"
    *ngIf="item.url"
    [routerLink]="[item.url]"
    [target]="item.openInNewTab ? '_blank' : '_self'"
  >
    <img class="icon-view" [src]="item.iconUrl" *ngIf="item.iconUrl" [title]="item.tooltip|translate" width="40" height="40" [alt]="'brand-logo'"/>
  </a>

</ng-container>
