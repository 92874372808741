import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CoreCommonModule} from '@core/common.module';
import {NotAuthorizedComponent} from './not-authorized/not-authorized.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {ErrorComponent} from './error/error.component';
import {TranslateModule} from '@ngx-translate/core';


// routing
const routes: Routes = [
  {
    path: 'miscellaneous/not-authorized/:logout',
    component: NotAuthorizedComponent
  },
  {
    path: 'miscellaneous/not-authorized',
    component: NotAuthorizedComponent
  },
  {
    path: 'miscellaneous/maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'miscellaneous/error',
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: 'miscellaneous/error'
  }
];

@NgModule({
  declarations: [NotAuthorizedComponent, MaintenanceComponent, ErrorComponent],
  imports: [CommonModule, RouterModule.forChild(routes), CoreCommonModule, TranslateModule]
})
export class MiscellaneousModule {
}
