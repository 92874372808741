import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnChanges {

  @Input()
  dropdownStyle: string = 'primary';
  @Input()
  width: string = '';
  @Input()
  items: any[];
  @Input()
  displayField: string = '';  //use this field when working with list of objects to specify field to show in dropdown
  @Input()
  title: string = '';
  @Input()
  placeholder: string = ''; //use this field if dropdown should have placeholder before setting first value
  @Output()
  selectedOption = new EventEmitter<string>();
  selectedItemInternal: string;
  @Input()
  verticalAlign = true;
  @Input()
  cssClasses = 'form-group';
  @Input()
  firstValue;
  @Input()
  disabled = false;
  @Input()
  tabAccess = true;
  @Input()
  hasError = false;

  @ViewChild('myInput') myInput!: ElementRef<HTMLInputElement>

  constructor() {
  }

  ngOnInit(): void {
    if (this.placeholder) {
      this.selectedItemInternal = this.placeholder;
    } else {
      this.setSelectedOption(this.firstValue ? this.firstValue : this.items[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.placeholder) {
      this.selectedItemInternal = this.placeholder;
    } else {
      if (changes.firstValue)
        this.setSelectedOption(changes.firstValue?.currentValue ? changes.firstValue?.currentValue : changes.options?.currentValue[0]);
    }
  }

  setSelectedOption(option) {
    if (!option) return;
    this.selectedItemInternal = this.displayField && option ? option[this.displayField] : option;
    this.selectedOption.emit(option);
  }
}
