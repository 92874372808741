import {Injectable} from '@angular/core';
import {CourseFilter} from '../models/course/course-filter';
import {CourseConstantsService} from './course-constants.service';
import {SubordinateFilters} from '../models/course/participant/subordinate-filters';
import {UserCourseFilterService} from './users-course-filter.service';
import {UsersCourseFilter} from '../models/user/users-course-filter';
import {SimpleEntity} from '../models/shared/simple-entity';
import {CourseConstants} from '../models/course/course-constants';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CourseFilterService {

    courseConstants$: BehaviorSubject<CourseConstants | null> = new BehaviorSubject<CourseConstants | null>(null);

    constructor(private courseConstantsService: CourseConstantsService,
                private userCourseFilterService: UserCourseFilterService) {
    }

    initialize() {
        this.getFilterConstants();
    }

    getFilterConstants(): void {
        this.courseConstantsService.getCourseConstantsForFilters().subscribe((courseConstants) => {
            this.currentFilters.schoolYear = courseConstants.currentSchoolYear;
            this.subordinateFilters.schoolYear = courseConstants.currentSchoolYear;
            this.courseConstants$.next(courseConstants);
          });
    }

    private currentFilters: CourseFilter = {
        schoolYear: {startDate: new Date(''), endDate: new Date(''), stringRepresentation: 'NO_SELECTION'},
        groupBy: {name: 'NO_GROUPING', sortKey: '', noValueTranslation: 'NO_GROUPING'},
        courseType: 'ALL_COURSES'
    } as CourseFilter;

    private subordinateFilters: SubordinateFilters = {
        schoolYear: {startDate: new Date(''), endDate: new Date(''), stringRepresentation: 'NO_SELECTION'},
        applicationStatus: 'NO_SELECTION'
    } as SubordinateFilters;

    updateFilters(filter: CourseFilter): void {
        this.currentFilters = filter;
        this.setSortWithGrouping();
    }

    updateSubordinateFilters(filter: SubordinateFilters): void {
        this.subordinateFilters = filter;
    }

    getFilter(): CourseFilter {
        return this.currentFilters;
    }

    getSubordinateFilter(): SubordinateFilters {
        return this.subordinateFilters;
    }

    resetFilters() {
        this.currentFilters = {
            dateFrom: new Date(''),
            dateTo: new Date(''),
            schoolYear: {startDate: new Date(''), endDate: new Date(''), stringRepresentation: 'NO_SELECTION'},
            groupBy: {name: 'NO_GROUPING', sortKey: '', noValueTranslation: 'NO_GROUPING'},
            sortBy: {name: 'TITLE', sortKey: 'title,startDate', noValueTranslation: ''},
            sortWithGrouping: 'title, date',
            courseType: 'ALL_COURSES'
        } as CourseFilter;
        this.subordinateFilters = {
            dateFrom: new Date(''),
            dateTo: new Date(''),
            schoolYear: {startDate: new Date(''), endDate: new Date(''), stringRepresentation: 'NO_SELECTION'},
            applicationStatus: 'NO_SELECTION'
        } as SubordinateFilters;
    }

    setSortWithGrouping(): void {
      if (this.currentFilters.groupBy && this.currentFilters.groupBy.sortKey !== '') {
        this.currentFilters.sortWithGrouping = this.currentFilters.groupBy.sortKey + ',' + this.currentFilters.sortBy.sortKey;
      } else if (this.currentFilters.sortBy && this.currentFilters.sortBy.sortKey !== '') {
        this.currentFilters.sortWithGrouping = this.currentFilters.sortBy.sortKey;
      }
    }

    loadUsersCourseFilter(): void {
      this.userCourseFilterService.getUsersCourseFilter().subscribe(res => {
        if (res) {
          this.currentFilters.courseType = res.organiserType;
          this.loadSortBy(res.sortBy);
          this.currentFilters.title = res.title;
          this.currentFilters.speakers = res.speakers;
          this.currentFilters.schoolYear = {startDate: res.dateFrom, endDate:  res.dateTo, stringRepresentation: 'NO_SELECTION'};

          this.currentFilters.courseStatuses = res.courseStatuses;
          this.currentFilters.courseApplicationStatuses = res.courseApplicationStatuses;
          this.currentFilters.tags = res.tags;
          this.currentFilters.provinces = res.provinces;

          this.currentFilters.disciplines = res.disciplinesPrimary;
          this.currentFilters.disciplinesSecondary = res.disciplinesSecondary;
          this.currentFilters.coreProcesses = res.coreProcesses;
          this.currentFilters.formationTypes = res.formationTypes;
        }
      })
    }

    loadSortBy(sortBy: string): void {
      if (!sortBy || sortBy === '' || sortBy === 'DATE') {
        this.currentFilters.sortBy = {name: 'DATE', sortKey: 'startDate,title', noValueTranslation: ''};
      } else if (sortBy === 'RECENT') {
        this.currentFilters.sortBy = {name: 'RECENT', sortKey: 'createDate,startDate', noValueTranslation: ''};
      } else {
        this.currentFilters.sortBy = {name: 'TITLE', sortKey: 'title,startDate', noValueTranslation: ''};
      }
    }

    saveUsersCourseFilter(): void {
      let usersCourseFilter = {
        organiserType: this.currentFilters.courseType,
        sortBy: this.currentFilters.sortBy.name,
        title: this.currentFilters.title,
        speakers: this.currentFilters.speakers,
        dateFrom: this.currentFilters.dateFrom,
        dateTo: this.currentFilters.dateTo,
        courseStatuses: this.currentFilters.courseStatuses,
        courseApplicationStatuses: this.currentFilters.courseApplicationStatuses,
        tags: this.currentFilters.tags,
        provinces: this.currentFilters.provinces,
        disciplinesPrimary: this.currentFilters.disciplines as SimpleEntity[],
        disciplinesSecondary: this.currentFilters.disciplinesSecondary as SimpleEntity[],
        coreProcesses: this.currentFilters.coreProcesses as SimpleEntity[],
        formationTypes: this.currentFilters.formationTypes as SimpleEntity[],
      } as UsersCourseFilter;

      this.userCourseFilterService.saveUsersCourseFilter(usersCourseFilter).subscribe();
    }
}
