import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './alert/alert.component';
import {PaginationComponent} from './pagination/pagination.component';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreCommonModule} from '../../../@core/common.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        NgbPaginationModule,
        CoreCommonModule,
        TranslateModule
    ],
    declarations: [
        AlertComponent,
        PaginationComponent
    ],
    exports: [
        AlertComponent,
        PaginationComponent
    ]
})
export class CoreComponentsModule{ }
