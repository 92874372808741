import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'pagination-component',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

    @Input()
    pageSize: number = 10;
    @Input()
    collectionSize: number = 0;
    @Input()
    page: number = 1;

    @Output()
    pageChange = new EventEmitter<number>();

    @Output()
    pageSizeChange = new EventEmitter<number>();


    onPageChange(selectedPage: number) {
        this.page = selectedPage;
        this.pageChange.emit(this.page);
    }

    onPageSizeChange(pageSize: number) {
        this.pageSize = pageSize
        this.pageSizeChange.emit(this.pageSize);
    }
}
