import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

const TOKEN = 'token';
const ROLES = 'roles';
const LANGUAGE = 'language';
const LAST_LOGIN = 'lastLogin';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public rolesChanged: Subject<string[]> = new Subject<any>();

  constructor() {
  }

  clearConfig() {
    localStorage.removeItem('config')
  }

  clear() {
    localStorage.clear();
  }

  clearUserData() {
    localStorage.removeItem(ROLES);
    localStorage.removeItem(TOKEN);
  }

  getToken(): string {
    return localStorage.getItem(TOKEN);
  }

  getRoles(): string[] {
    return this.get(ROLES);
  }

  setToken(token: string) {
    localStorage.setItem(TOKEN, token);
  }

  setRoles(roles: string[]) {
    localStorage.setItem(ROLES, JSON.stringify(roles));
    this.emitRoleChangeEvent();
  }

  setLastLogin(lastLogin: Date) {
    if (lastLogin) {
      localStorage.setItem(LAST_LOGIN, JSON.stringify(lastLogin));
    } else {
      localStorage.removeItem(LAST_LOGIN);
    }
  }

  setLanguage(languageId) {
    localStorage.setItem(LANGUAGE, languageId);
  }

  getLanguage(): string {
    return localStorage.getItem(LANGUAGE);
  }

  getLastLogin(): Date {
    const lastLoginInString = localStorage.getItem(LAST_LOGIN);
    if (lastLoginInString) {
      return JSON.parse(lastLoginInString);
    }
    return null;
  }

  private get(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  private emitRoleChangeEvent() {
    this.rolesChanged.next();
  }
}
