<div [ngStyle]="customStyle" class="form-group {{hasError ? 'is-invalid': ''}}">
  <label>{{title | translate}}</label>
  <ng-select
    (change)=selectedChanged($event)
    [(ngModel)]="selectedItemInternal"
    [closeOnSelect]="true"
    [items]="itemSelect | async"
    [multiple]="false"
    [ngStyle]="{'max-width': 'inherit'}"
    [searchable]="true"
    (search)="onSearch($event)"
    [minTermLength]="2"
    [disabled]="disabled"
    placeholder="{{placeholder | translate}}"
    bindLabel="{{displayField}}"
    class="custom-form-component {{hasError? 'invisible-error': ''}}"
  >
    <ng-template ng-option-tmp let-item="item" ng-label-tmp>
      <div title="item">{{displayField ? (item[displayField]|translate)  : (item|translate)}}</div>
    </ng-template>

    <ng-template let-clear="clear" let-item="item" ng-label-tmp>
      <span class="ng-value-label"> {{ displayField ? (item[displayField]|translate)  : (item|translate)}}</span>
      <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon right">×</span>
    </ng-template>
  </ng-select>
</div>
