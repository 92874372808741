import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UsersCourseFilter} from '../models/user/users-course-filter';

const BASE_URL = '/api/user/course/filter';

@Injectable({
  providedIn: 'root'
})
export class UserCourseFilterService {
  constructor(private http: HttpClient) {
  }

  getUsersCourseFilter(): Observable<UsersCourseFilter> {
    return this.http.get<UsersCourseFilter>(BASE_URL);
  }

  saveUsersCourseFilter(usersCourseFilter: UsersCourseFilter): Observable<UsersCourseFilter> {
    return this.http.post<UsersCourseFilter>(BASE_URL, usersCourseFilter);
  }

  getCourseFilterForUser(userId: number): Observable<UsersCourseFilter> {
    return this.http.get<UsersCourseFilter>(BASE_URL + `/${userId}`);
  }

}
