import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {ChangePasswordModel} from '../models/auth/change-password';
import {UserProfileInfo} from '../models/user/profile-info';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  public profilePicture: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  constructor(private http: HttpClient) {
  }

  public getUserProfile(): Observable<UserProfileInfo> {
    return this.http.get<UserProfileInfo>('/api/users/profile')
      .pipe(
        tap(userProfile =>
          this.profilePicture.next(userProfile.imageSrc)
        )
      );
  }

  public updateUserProfileInfo(userProfileInfo: UserProfileInfo): Observable<UserProfileInfo> {
    return this.http.post<any>('/api/users/edit-profile', userProfileInfo);
  }

  public updateChangePassword(changePasswordDto: ChangePasswordModel): Observable<UserProfileInfo> {
    return this.http.post<any>('/api/users/change-password', changePasswordDto);
  }
}
