import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PlatformConfigurationService} from '../../../core/services/platform-configuration.service';

@Component({
  selector: 'app-cookie-consent-modal',
  templateUrl: './cookie-consent-modal.component.html',
  styleUrls: ['./cookie-consent-modal.component.scss'],
})
export class CookieConsentModalComponent {
  shouldShowLoading = false;

  constructor(
    public activeModal: NgbActiveModal,
    private platformConfigurationService: PlatformConfigurationService
  ) {
  }

  onAccept() {
    localStorage.setItem('cookie-consent', 'true');
    this.sendCookieConsentToOtherApps();
    this.shouldShowLoading = true;
    setTimeout(() => {
      this.activeModal.dismiss('Accepted');
    }, 2100);
  }

  sendCookieConsentToOtherApps() {
    this.platformConfigurationService.subscribeForPortalConfiguration().subscribe(configuration => {
      const iframeAcademie = document.getElementsByTagName('iframe')[0].contentWindow;
      const iframeMagazine = document.getElementsByTagName('iframe')[1].contentWindow;
      document.getElementsByTagName('iframe')[0].src = configuration.academieUrl + '/';
      document.getElementsByTagName('iframe')[1].src = configuration.magazineUrl + '/';
      setTimeout(() => {
        iframeAcademie.postMessage(JSON.stringify({
          key: 'cookie-consent',
          data: 'true'
        }), configuration.academieUrl);
        iframeMagazine.postMessage(JSON.stringify({
          key: 'cookie-consent',
          data: 'true'
        }), configuration.magazineUrl);
      }, 2000);
    })
  }
}
