import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CourseConstants} from '../models/course/course-constants';


@Injectable({
  providedIn: 'root'
})
export class CourseConstantsService {

  private COURSE_API = '/api/courses/constants';

  constructor(private http: HttpClient) {
  }

  getCourseConstantsForFilters(): Observable<CourseConstants> {
    return this.http.get<CourseConstants>(this.COURSE_API + '/filters');
  }

  getCourseConstantsForEdit(courseId: number | undefined): Observable<CourseConstants> {
    return this.http.get<CourseConstants>(this.COURSE_API + '/' + courseId);
  }

  getCourseConstantsForCreate(): Observable<CourseConstants> {
    return this.http.get<CourseConstants>(this.COURSE_API + '/create');
  }

  getCourseConstantsForCopy(moveParticipants: boolean): Observable<CourseConstants> {
    return this.http.get<CourseConstants>(this.COURSE_API + '/copy?moveParticipants=' + moveParticipants);
  }

  getCourseApplicationStatusConstants(): Observable<String[]> {
    return this.http.get<String[]>(this.COURSE_API + '/application-statuses');
  }

  getCourseConstantsForCreatePersonal(): Observable<CourseConstants> {
    return this.http.get<CourseConstants>(this.COURSE_API + '/personal/create');
  }

}
