<div [ngStyle]="customStyle" class="form-group {{hasError && submitted? 'is-invalid': ''}}">
  <label *ngIf="title !== ''">{{title | translate}}</label>
  <ng-select
    (change)=selectedChanged($event)
    [(ngModel)]="selectedItemsInternal"
    [closeOnSelect]="false"
    [multiple]="true"
    [ngStyle]="{'min-width':getMinWidth()+'px', 'max-width': 'inherit'}"
    [searchable]="searchable"
    [clearSearchOnAdd]="searchable"
    [disabled]="isPublished"
    placeholder="{{placeholder | translate}}"
    class="custom-form-component {{hasError? 'invisible-error': ''}}"
  >
<!--    this is used for dropdown options-->
    <div *ngIf="isArticleTag">
      <ng-option *ngFor="let item of items" [value]="item">
        {{ 'ARTICLE_TAGS.' + item|translate }}
      </ng-option>
    </div>
    <div *ngIf="!isArticleTag">
      <ng-option *ngFor="let item of items" [value]="item">
        {{ displayField ? (item[displayField]|translate) : (item|translate) }}
      </ng-option>
    </div>
<!--    this is used for selected options-->
    <ng-template let-clear="clear" let-item="item" ng-label-tmp>
      <span *ngIf="isArticleTag" class="ng-value-label"> {{'ARTICLE_TAGS.' + item|translate}}</span>
      <span *ngIf="!isArticleTag" class="ng-value-label"> {{ displayField ? (item[displayField]|translate) : (item|translate) }}</span>
      <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon right">×</span>
    </ng-template>
  </ng-select>
</div>
