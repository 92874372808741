import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private _router: Router,
              private _authenticationService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
     if (!this._authenticationService.isLoggedIn()) {
       if (window.location.pathname === '/') {
         this._router.navigateByUrl('/public');
       } else {
         this._router.navigate(['miscellaneous/not-authorized']);
       }
       return false;
     }

    if(!this.checkIfRouteContainsRole(route)){
      if(this._authenticationService.isLoggedIn())
        this._router.navigate(['miscellaneous/not-authorized']);
    }

    return true;
  }

  private checkIfRouteContainsRole(route): boolean{
    const userRoles = this._authenticationService.getRoles();
    const routeRoles = route.data.roles;

    if(routeRoles){
      return this.hasRole(userRoles, routeRoles)
    }

    return true;
  }

  private hasRole(userRoles, routeRoles): boolean {
    for(let userRole in userRoles){
      if(routeRoles.indexOf(userRoles[userRole]) > -1)
        return true
    }
    return false
  }

}
