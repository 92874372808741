<div class="card-footer p-2">
  <div class="grid-container">
    <div></div>
    <div class="middle-column">
      <ngb-pagination (pageChange)="onPageChange($event)"
                      [(page)]="page"
                      [boundaryLinks]="true"
                      [collectionSize]="collectionSize"
                      [maxSize]="5"
                      [pageSize]="pageSize"
                      [rotate]="true"
                      [size]="'lg'">
      </ngb-pagination>
    </div>
    <div class="right-column">
      <label>{{'SHOW' | translate}}
        <select (ngModelChange)="onPageSizeChange($event)"
                [(ngModel)]="pageSize"
                class="form-control">
          <option [ngValue]="10" value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
        </select>
        {{'ENTRIES' | translate}}</label>
    </div>
  </div>
</div>
